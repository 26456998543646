<template>
  <div
    class="d-flex align-items-center"
    :style="[
      params?.withBreakpoints
        ? { 'max-width': '100px', 'text-overflow': 'clip', 'white-space': 'normal' }
        : {},
    ]"
    :class="[params.enableSorting && 'headerElement']"
    @click="onSortRequested($event)"
  >
    <b class="mr-2" v-if="params?.bold">{{ params.displayName }}</b>
    <div class="mr-2" v-else>
      {{ params.displayName }}
    </div>
    <div v-if="params.enableSorting">
      <i class="fa fa-long-arrow-alt-up" :class="ascSort"></i>
    </div>
    <div v-if="params.enableSorting">
      <i class="fa fa-long-arrow-alt-down" :class="descSort"></i>
    </div>
  </div>
</template>
<style scoped>
.active {
  color: black;
}
.headerElement {
  cursor: pointer;
}
</style>
<script>
export default {
  data: function () {
    return {
      ascSort: null,
      descSort: null,
      noSort: null,
    };
  },
  beforeMount() {},
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    onSortChanged() {
      this.ascSort = this.descSort = this.noSort = 'inactive';
      if (this.params.column.isSortAscending()) {
        this.ascSort = 'active';
      } else if (this.params.column.isSortDescending()) {
        this.descSort = 'active';
      } else {
        this.noSort = 'active';
      }
    },

    onSortRequested(event) {
      if (this.params.enableSorting) {
        if (this.noSort === 'active') {
          this.params.setSort('asc', event.shiftKey);
        } else if (this.ascSort === 'active') {
          this.params.setSort('desc', event.shiftKey);
        } else if (this.descSort === 'active') {
          this.params.setSort('', event.shiftKey);
        }
      }
    },
  },
};
</script>
